import { takeLatest, put, call } from 'redux-saga/effects';
import { SupportTicketActionTypes } from '../actions/supportTicketActionTypes';
import {
  getSupportTicketsRequestSuccess,
  getSupportTicketsRequestError,
  createSupportTicketRequestSuccess,
  createSupportTicketRequestError,
  updateSupportTicketRequestSuccess,
  updateSupportTicketRequestError,
  deleteSupportTicketRequestSuccess,
  deleteSupportTicketRequestError
} from '../actions/supportTicketActions';
import { apiService } from '../../../services/api';

function* getSupportTicketRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageSupportTickets, req.payload);
    // Dispatch the success action with the API response
    yield put(getSupportTicketsRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(getSupportTicketsRequestError(error.message));
  }
}

function* createSupportTicketRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageSupportTickets, req.payload);

    // Dispatch the success action with the API response
    yield put(createSupportTicketRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(createSupportTicketRequestError(error.message));
  }
}

function* updateSupportTicketRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageSupportTickets, req.payload);

    // Dispatch the success action with the API response
    yield put(updateSupportTicketRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(updateSupportTicketRequestError(error.message));
  }
}

function* deleteSupportTicketRequestSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.manageSupportTickets, req.payload);

    // Dispatch the success action with the API response
    yield put(deleteSupportTicketRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(deleteSupportTicketRequestError(error.message));
  }
}

export function* watchSupportTicketRequest() {
  yield takeLatest(SupportTicketActionTypes.GET_SUPPORT_TICKETS_REQUEST, getSupportTicketRequestSaga);
  yield takeLatest(SupportTicketActionTypes.CREATE_SUPPORT_TICKET_REQUEST, createSupportTicketRequestSaga);
  yield takeLatest(SupportTicketActionTypes.UPDATE_SUPPORT_TICKET_REQUEST, updateSupportTicketRequestSaga);
  yield takeLatest(SupportTicketActionTypes.DELETE_SUPPORT_TICKET_REQUEST, deleteSupportTicketRequestSaga);
}
