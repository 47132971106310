// features/createClass/actions/createClassActions.ts
import { UserTasksActionTypes, UserTasksAction } from './userTasksActionTypes';

export const userTasksRequest = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_REQUEST,
  payload: data
});

export const userTasksRequestSuccess = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_REQUEST_SUCCESS,
  payload: data
});

export const userTasksRequestError = (error: string): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_REQUEST_ERROR,
  payload: error
});

export const userTasksUpdateRequest = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST,
  payload: data
});

export const userTasksUpdateRequestSuccess = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST_SUCCESS,
  payload: data
});

export const userTasksUpdateRequestError = (error: string): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_UPDATE_REQUEST_ERROR,
  payload: error
});

export const userTasksDeleteRequest = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_DELETE_REQUEST,
  payload: data
});

export const userTasksDeleteRequestSuccess = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_DELETE_REQUEST_SUCCESS,
  payload: data
});

export const userTasksDeleteRequestError = (error: string): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_DELETE_REQUEST_ERROR,
  payload: error
});

export const userTasksAddCommentRequest = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_ADD_COMMENT_REQUEST,
  payload: data
});

export const userTasksAddCommentRequestSuccess = (data: any): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_ADD_COMMENT_REQUEST_SUCCESS,
  payload: data
});

export const userTasksAddCommentRequestError = (error: string): UserTasksAction => ({
  type: UserTasksActionTypes.USER_TASKS_ADD_COMMENT_REQUEST_ERROR,
  payload: error
});
