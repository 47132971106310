import { ProfileActionTypes, ProfileAction } from '../actions/ProfileActionTypes';

interface ProfileState {
  data: string[];
  update_data: string[];
  profile_loading: boolean;
  user_profile_loading: boolean;
  update_profile_loading: boolean;
  delete_profile_loading: boolean;
  error: string | null;
}

const initialState: ProfileState = {
  data: [],
  update_data: [],
  profile_loading: false,
  user_profile_loading: false,
  update_profile_loading: false,
  delete_profile_loading: false,
  error: null
};

const profileReducer = (state = initialState, action: ProfileAction): ProfileState => {
  switch (action.type) {
    case ProfileActionTypes.PROFILE_REQUEST:
      return { ...state, profile_loading: true };
    case ProfileActionTypes.PROFILE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, profile_loading: false, error: null };
    case ProfileActionTypes.PROFILE_REQUEST_ERROR:
      return { ...state, profile_loading: false, error: action.payload };
    case ProfileActionTypes.USER_PROFILE_REQUEST:
      return { ...state, user_profile_loading: true };
    case ProfileActionTypes.USER_PROFILE_REQUEST_SUCCESS:
      return { ...state, data: action.payload, user_profile_loading: false, error: null };
    case ProfileActionTypes.USER_PROFILE_REQUEST_ERROR:
      return { ...state, user_profile_loading: false, error: action.payload };
    case ProfileActionTypes.UPDATE_PROFILE_REQUEST:
      return { ...state, update_profile_loading: true, profile_loading: true };
    case ProfileActionTypes.UPDATE_PROFILE_REQUEST_SUCCESS:
      return { ...state, update_data: action.payload, update_profile_loading: false, profile_loading: false, error: null };
    case ProfileActionTypes.UPDATE_PROFILE_REQUEST_ERROR:
      return { ...state, update_profile_loading: false, profile_loading: false, error: action.payload };
    case ProfileActionTypes.DELETE_PROFILE_REQUEST:
      return { ...state, delete_profile_loading: true };
    case ProfileActionTypes.DELETE_PROFILE_REQUEST_SUCCESS:
      console.log('action.payload')
      console.log(action.payload)
      return { ...state, data: action.payload, delete_profile_loading: false, error: null };
    case ProfileActionTypes.DELETE_PROFILE_REQUEST_ERROR:
      return { ...state, delete_profile_loading: false, error: action.payload };
    default:
      return state;
  }
};

export default profileReducer;
