import { lazy } from 'react';
import Loadable from '../Pages/LayoutPage/Shared/loadable/Loadable';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { WebSocketProvider } from '../Pages/Messenger/context/webSocketContext';
const NewTutorSignup = Loadable(lazy(() => import('../Pages/Admin/Users/single/NewTutorSignup')));
const ManagementInvoices = Loadable(lazy(() => import('../Pages/Invoices')));
const ManageBookings = Loadable(lazy(() => import('../Pages/Admin/Booking/manageBooking')));
const ManageEvents = Loadable(lazy(() => import('../Pages/Admin/Events/manageEvents')));
const ManageCareersApplications = Loadable(lazy(() => import('../Pages/Admin/Careers/ManageCareersApplication')));
const RescheduleRequests = Loadable(lazy(() => import('../Pages/RescheduleRequests')));
const ProtectedRoute = Loadable(lazy(() => import('./ProtectedRoute')));
const SidebarLayout = Loadable(lazy(() => import('../Pages/LayoutPage/SidebarLayout')));
const BaseLayout = Loadable(lazy(() => import('../Pages/LayoutPage/BaseLayout')));
const SignIn = Loadable(lazy(() => import('../Pages/Signin/SignIn')));
const ClassNotes = Loadable(lazy(() => import('../Pages/ClassNotes/ClassNotes')));
const ManageClass = Loadable(lazy(() => import('../Pages/ManageClass')));
const Tasks = Loadable(lazy(() => import('../Pages/Tasks')));
const NewTask = Loadable(lazy(() => import('../Pages/Tasks/NewTask')));
const Students = Loadable(lazy(() => import('../Pages/Students/Students')));
const Applicants = Loadable(lazy(() => import('../Pages/JoinTeam/review/index')));
const ManageCategories = Loadable(lazy(() => import('../Pages/Admin/Categories/ManageCategories')));
const ManageCourses = Loadable(lazy(() => import('../Pages/Admin/Courses/ManageCourses')));
const Events = Loadable(lazy(() => import('../Pages/Events/Events')));
const CreateEvent = Loadable(lazy(() => import('../Pages/Events/pages/CreateEvent')));
const TeachingMaterials = Loadable(lazy(() => import('../Pages/HomePage/TeachingMaterials/shop/')));
const CreateTeachingMaterials = Loadable(lazy(() => import('../Pages/HomePage/TeachingMaterials/create/')));
const TeachingMaterialsCart = Loadable(lazy(() => import('../Pages/HomePage/TeachingMaterials/shop/Cart')));
const EventDetails = Loadable(lazy(() => import('../Pages/Events/pages/EventDetails')));
const ContactUs = Loadable(lazy(() => import('../Pages/ContactUs/ContactUs')));
const JoinTeam = Loadable(lazy(() => import('../Pages/JoinTeam/JoinTeamPage')));
const Dashboard = Loadable(lazy(() => import('../Pages/Dashboard/Dashboard')));
const KGStudentDashboard = Loadable(lazy(() => import('../Pages/Dashboard/KGStudentDashboard')));
const ForgotPassword = Loadable(lazy(() => import('../Pages/ForgotPassword/ForgotPassword')));
const VerifyPassword = Loadable(lazy(() => import('../Pages/ForgotPassword/VerifyPassword')));
const HomePage = Loadable(lazy(() => import('../Pages/HomePage/HomePage')));
const ResourceList = Loadable(lazy(() => import('../Pages/HomePage/ResourceList')));
const Careers = Loadable(lazy(() => import('../Pages/Careers/')));
const ManageTeachingMaterials = Loadable(lazy(() => import('../Pages/HomePage/TeachingMaterials/')));
const Locations = Loadable(lazy(() => import('../Pages/HomePage/Locations')));
const Homework = Loadable(lazy(() => import('../Pages/Homework/Homework')));
const CreateHomeworkQuestions = Loadable(lazy(() => import('../Pages/Homework/CreateHomeworkQuestions')));
const EnrolledSubjects = Loadable(lazy(() => import('../Pages/Homework/EnrolledSubjects')));
const Rewards = Loadable(lazy(() => import('../Pages/Rewards/Rewards')));
const EarnedGifts = Loadable(lazy(() => import('../Pages/Rewards/EarnedGifts')));
const CompletedHomeworks = Loadable(lazy(() => import('../Pages/Homework/CompletedHomework')));
const HomeworkList = Loadable(lazy(() => import('../Pages/Homework/HomeworkList')));
const HomeworkPageList = Loadable(lazy(() => import('../Pages/Homework/HomeworkPages')));
const Games = Loadable(lazy(() => import('../Pages/Games/index')));
const Messenger = Loadable(lazy(() => import('../Pages/Messenger')));
const Users = Loadable(lazy(() => import('../Pages/Admin/Users')));
const Schedule = Loadable(lazy(() => import('../Pages/Schedule/Schedule')));
const Reschedule = Loadable(lazy(() => import('../Pages/Schedule/Reschedule')));
const UpdateSchedule = Loadable(lazy(() => import('../Pages/Schedule/components/SetSchedule')));
const Signup = Loadable(lazy(() => import('../Pages/Signup/Signup')));
const Trial = Loadable(lazy(() => import('../Pages/Signup/Trial')));
const VerifyUser = Loadable(lazy(() => import('../Pages/Signup/VerifyUser')));
const ResendCode = Loadable(lazy(() => import('../Pages/Signup/ResendCode')));
const CourseDetails = Loadable(lazy(() => import('../Pages/Courses/Details/index')));
const UserSettings = Loadable(lazy(() => import('../Pages/Users/settings')));
const UserProfile = Loadable(lazy(() => import('../Pages/Admin/Users/single')));
const PublicUserProfile = Loadable(lazy(() => import('../Pages/Admin/Users/single/TeachersIndex')));
const Support = Loadable(lazy(() => import('../Pages/Users/support')));
const ManageSupportTickets = Loadable(lazy(() => import('../Pages/Admin/Support/manageSupportTickets')));
const Error = Loadable(lazy(() => import('../Pages/LayoutPage/Shared/error/Error')));
const PublicRoute = Loadable(lazy(() => import('./PublicRoute')));
const HomeworkManager = Loadable(lazy(() => import('../Pages/Homework/HomeworkManager')));
const CreateCourse = Loadable(lazy(() => import('../Pages/Courses/CreateCourse')));
const CreateModelEvents = Loadable(lazy(() => import('../Pages/Events/pages/CreateModelEvents')));
const PaymentSuccess = Loadable(lazy(() => import('../Pages/Payments/PaymentSuccess')));
const ManageLocations = Loadable(lazy(() => import('../Pages/Admin/Locations/ManageLocations')));
const ManageCareers = Loadable(lazy(() => import('../Pages/Admin/Careers/ManageCareers')));
const Meetings = Loadable(lazy(() => import('../Pages/Meetings/index')));
const JoinMeeting = Loadable(lazy(() => import('../Pages/Meetings/JoinMeeting')));
const AboutUs = Loadable(lazy(() => import('../Pages/HomePage/AboutUs')));
const TeachingMaterialDetails = Loadable(lazy(() => import('../Pages/HomePage/TeachingMaterials/single')));
const ToDoItems = Loadable(lazy(() => import('../Pages/ToDoItems')));
const CareerDetail = Loadable(lazy(() => import('../Pages/Careers/CareerDetail')));
const LowLatencyHomework = Loadable(lazy(() => import('../Pages/Homework/LowLatencyHomework')));
const ManagementInvoicesView = Loadable(lazy(() => import('../Pages/Invoices/single/index')));

const Router = [
  {
    path: '/',
    element: (
      <WebSocketProvider>
        <ProtectedRoute>
          <SidebarLayout />
        </ProtectedRoute>
      </WebSocketProvider>
    ),
    children: [
      { path: '/', element: <Navigate to="/dashboard" /> },
      { path: '/dashboard', element: <Dashboard /> },
      { path: '/settings', element: <UserSettings /> },
      { path: '/profile/:userId', element: <UserProfile /> },
      {
        path: '/messages',
        element: <Messenger />
      },
      {
        path: '/meetings/:scheduleId',
        element: <Meetings />
      },
      {
        path: '/joinMeeting',
        element: <JoinMeeting />
      },
      { path: '/schedules', element: <Schedule /> },
      { path: '/reschedule/:rescheduleDetails', element: <Reschedule /> },
      { path: '/rescheduleRequests', element: <RescheduleRequests /> },
      { path: '/updateSchedule', element: <UpdateSchedule /> },
      { path: '/enrolledSubjects', element: <EnrolledSubjects /> },
      { path: '/homeworks', element: <HomeworkList /> },
      { path: '/homeworks/homeworkProblems', element: <Homework /> },
      { path: '/newTutorSignup', element: <NewTutorSignup /> },
      { path: '/homework/homeworkProblems/:hwdetails', element: <Homework /> },
      { path: '/homeworks/:subject', element: <HomeworkList /> },
      { path: '/createHomeworkQuestions', element: <CreateHomeworkQuestions /> },
      { path: '/createHomeworkQuestions/:class_id', element: <CreateHomeworkQuestions /> },
      { path: '/completedHomeworks', element: <CompletedHomeworks /> },
      { path: '/games', element: <Games /> },
      { path: '/homeworks/homeworkId', element: <HomeworkPageList /> },
      { path: '/rewards', element: <Rewards /> },
      { path: '/earnedGifts', element: <EarnedGifts /> },
      { path: '/classnotes', element: <ClassNotes /> },
      { path: '/tasks', element: <Tasks /> },
      { path: '/newTask', element: <NewTask /> },
      { path: '/todoItems', element: <ToDoItems /> },
      { path: '/manageClass/:scheduleId', element: <ManageClass /> },
      { path: '/createCourse', element: <CreateCourse /> },
      { path: '/createModelEvents', element: <CreateModelEvents /> },
      { path: '/students', element: <Students /> },
      { path: '/applicants', element: <Applicants /> },
      { path: '/invoices', element: <ManagementInvoices /> },
      { path: '/invoices/:invoiceId', element: <ManagementInvoicesView /> },
      { path: '/manageCategories', element: <ManageCategories /> },
      { path: '/manageCourses', element: <ManageCourses /> },
      { path: '/manageLocations', element: <ManageLocations /> },
      { path: '/manageCareers', element: <ManageCareers /> },
      { path: '/manageCareerApplications', element: <ManageCareersApplications /> },
      { path: `/manageBookings`, element: <ManageBookings /> },
      { path: '/manageEvents', element: <ManageEvents /> },
      { path: '/users', element: <Users /> },
      { path: '/contactSupport', element: <Support /> },
      { path: '/manageSupportTickets', element: <ManageSupportTickets /> },
      { path: 'users/management/users/single/:userId', element: <UserProfile /> },
      { path: '/homeworkManager', element: <HomeworkManager /> },
      { path: '/createEvent', element: <CreateEvent /> },
      { path: '/createModelEvents', element: <CreateModelEvents /> },
      { path: '/lowLatencyHomework', element: <LowLatencyHomework /> },
      { path: '*', element: <Error /> }
    ]
  },
  {
    path: '/kgDashboard',
    element: (
      <WebSocketProvider>
        <ProtectedRoute>
          <KGStudentDashboard />
        </ProtectedRoute>
      </WebSocketProvider>
    )
  },
  {
    path: '/',
    element: <BaseLayout />,
    children: [
      {
        path: '/signin',
        element: (
          <PublicRoute>
            <SignIn />
          </PublicRoute>
        )
      },
      { path: '/home', element: <HomePage /> },
      { path: '/homepage', element: <HomePage /> },
      { path: '/resourceList', element: <ResourceList /> },
      { path: '/teachingMaterials', element: <TeachingMaterials /> },
      { path: '/teachingMaterials/reserved', element: <TeachingMaterialsCart /> },
      { path: '/manageTeachingMaterials', element: <ManageTeachingMaterials /> },
      { path: '/createTeachingMaterials', element: <CreateTeachingMaterials /> },
      { path: '/teachingMaterials/:teachingMaterialId', element: <TeachingMaterialDetails /> },
      { path: '/locations', element: <Locations /> },
      { path: '/signout', element: <SignIn /> },
      { path: '/signup', element: <Signup /> },
      { path: '/trial', element: <Trial /> },
      { path: '/events', element: <Events /> },
      { path: '/event/:eventId', element: <EventDetails /> },
      { path: '/teachers/:userId', element: <PublicUserProfile /> },
      { path: '/ContactUs', element: <ContactUs /> },
      { path: '/aboutus', element: <AboutUs /> },
      { path: '/CourseDetailPage', element: <CourseDetails /> },
      { path: '/CourseDetailPage/:courseId', element: <CourseDetails /> },
      { path: '/forgotPassword', element: <ForgotPassword /> },
      { path: '/verifyPassword', element: <VerifyPassword /> },
      { path: '/verifyUser', element: <VerifyUser /> },
      { path: '/resendCode', element: <ResendCode /> },
      { path: '/joinTeam', element: <JoinTeam /> },
      { path: '/careers', element: <Careers /> },
      { path: '/careers/:jobId', element: <CareerDetail /> },
      { path: '/payments/success', element: <PaymentSuccess /> },
      { path: '*', element: <Error /> }
    ]
  }
];

export const router = createBrowserRouter(Router);

export default Router;
