import { AIAgentsActionTypes, AIAgentsAction } from './aiAgentsActionTypes';

export const readingComprehensionRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.ReadingComprehension_REQUEST,
  payload: data
});

export const readingComprehensionRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.ReadingComprehension_REQUEST_SUCCESS,
  payload: data
});

export const readingComprehensionRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.ReadingComprehension_REQUEST_ERROR,
  payload: error
});

export const gradeReadingComprehensionRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeReadingComprehension_REQUEST,
  payload: data
});

export const gradeReadingComprehensionRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeReadingComprehension_REQUEST_SUCCESS,
  payload: data
});

export const gradeReadingComprehensionRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeReadingComprehension_REQUEST_ERROR,
  payload: error
});

export const matchTheFollowingRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.MatchTheFollowing_REQUEST,
  payload: data
});

export const matchTheFollowingRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.MatchTheFollowing_REQUEST_SUCCESS,
  payload: data
});

export const matchTheFollowingRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.MatchTheFollowing_REQUEST_ERROR,
  payload: error
});

export const gradeMatchTheFollowingRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeMatchTheFollowing_REQUEST,
  payload: data
});

export const gradeMatchTheFollowingRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeMatchTheFollowing_REQUEST_SUCCESS,
  payload: data
});

export const gradeMatchTheFollowingRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeMatchTheFollowing_REQUEST_ERROR,
  payload: error
});

export const chessRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.Chess_REQUEST,
  payload: data
});

export const chessRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.Chess_REQUEST_SUCCESS,
  payload: data
});

export const chessRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.Chess_REQUEST_ERROR,
  payload: error
});

export const gradeChessRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeChess_REQUEST,
  payload: data
});

export const gradeChessRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeChess_REQUEST_SUCCESS,
  payload: data
});

export const gradeChessRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeChess_REQUEST_ERROR,
  payload: error
});

export const spellingRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.Spelling_REQUEST,
  payload: data
});

export const spellingRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.Spelling_REQUEST_SUCCESS,
  payload: data
});

export const spellingRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.Spelling_REQUEST_ERROR,
  payload: error
});

export const gradeSpellingRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeSpelling_REQUEST,
  payload: data
});

export const gradeSpellingRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeSpelling_REQUEST_SUCCESS,
  payload: data
});

export const gradeSpellingRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeSpelling_REQUEST_ERROR,
  payload: error
});

export const sattutorRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.SATTutor_REQUEST,
  payload: data
});

export const sattutorRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.SATTutor_REQUEST_SUCCESS,
  payload: data
});

export const sattutorRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.SATTutor_REQUEST_ERROR,
  payload: error
});

export const gradeSATTutorRequest = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeSATTutor_REQUEST,
  payload: data
});

export const gradeSATTutorRequestSuccess = (data: any): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeSATTutor_REQUEST_SUCCESS,
  payload: data
});

export const gradeSATTutorRequestError = (error: string): AIAgentsAction => ({
  type: AIAgentsActionTypes.GradeSATTutor_REQUEST_ERROR,
  payload: error
});
