import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { requestClassError, requestClassSuccess } from '../actions/requestClassActions';
import { RequestClassActionTypes } from '../actions/requestClassActionTypes';

function* requestClassSaga(req) {
  try {
    const response = yield call(apiService.requestClass, req.payload);
    yield put(requestClassSuccess(response));
  } catch (error: any) {
    yield put(requestClassError(error.message));
  }
}

export function* watchRequestClass() {
  yield takeLatest(RequestClassActionTypes.REQUEST_CLASS_REQUEST, requestClassSaga);
}
