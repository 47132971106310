import { takeLatest, put, call } from 'redux-saga/effects';
import { apiService } from '../../../services/api';
import { BookingsActionTypes } from '../actions/bookingsActionTypes';
import {
  bookingsError,
  bookingsSuccess,
  approveBookingSuccess,
  rejectBookingSuccess,
  approveBookingError,
  rejectBookingError
} from '../actions/bookingsActions';

function* bookingsSaga(req) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.bookResource, req.payload);
    console.log(response);
    yield put(bookingsSuccess(response));
  } catch (error: any) {
    yield put(bookingsError(error.message));
  }
}

function* approveBookingSaga(req) {
  try {
    const response = yield call(apiService.bookResource, req.payload);
    yield put(approveBookingSuccess(response));
  } catch (error: any) {
    yield put(approveBookingError(error.message));
  }
}

function* rejectBookingSaga(req) {
  try {
    const response = yield call(apiService.bookResource, req.payload);
    yield put(rejectBookingSuccess(response));
  } catch (error: any) {
    yield put(rejectBookingError(error.message));
  }
}

export function* watchBookings() {
  yield takeLatest(BookingsActionTypes.BOOKINGS, bookingsSaga);
  yield takeLatest(BookingsActionTypes.APPROVE_BOOKING, approveBookingSaga);
  yield takeLatest(BookingsActionTypes.REJECT_BOOKING, rejectBookingSaga);
}
