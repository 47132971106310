import { SupportTicketActionTypes, SupportTicketAction } from './supportTicketActionTypes';

export const getSupportTicketsRequest = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.GET_SUPPORT_TICKETS_REQUEST,
  payload: data
});

export const getSupportTicketsRequestSuccess = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.GET_SUPPORT_TICKETS_REQUEST_SUCCESS,
  payload: data
});

export const getSupportTicketsRequestError = (error: string): SupportTicketAction => ({
  type: SupportTicketActionTypes.GET_SUPPORT_TICKETS_REQUEST_ERROR,
  payload: error
});

export const createSupportTicketRequest = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.CREATE_SUPPORT_TICKET_REQUEST,
  payload: data
});

export const createSupportTicketRequestSuccess = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.CREATE_SUPPORT_TICKET_REQUEST_SUCCESS,
  payload: data
});

export const createSupportTicketRequestError = (error: string): SupportTicketAction => ({
  type: SupportTicketActionTypes.CREATE_SUPPORT_TICKET_REQUEST_ERROR,
  payload: error
});

export const updateSupportTicketRequest = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.UPDATE_SUPPORT_TICKET_REQUEST,
  payload: data
});

export const updateSupportTicketRequestSuccess = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.UPDATE_SUPPORT_TICKET_REQUEST_SUCCESS,
  payload: data
});

export const updateSupportTicketRequestError = (error: string): SupportTicketAction => ({
  type: SupportTicketActionTypes.UPDATE_SUPPORT_TICKET_REQUEST_ERROR,
  payload: error
});

export const deleteSupportTicketRequest = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.DELETE_SUPPORT_TICKET_REQUEST,
  payload: data
});

export const deleteSupportTicketRequestSuccess = (data: any): SupportTicketAction => ({
  type: SupportTicketActionTypes.DELETE_SUPPORT_TICKET_REQUEST_SUCCESS,
  payload: data
});

export const deleteSupportTicketRequestError = (error: string): SupportTicketAction => ({
  type: SupportTicketActionTypes.DELETE_SUPPORT_TICKET_REQUEST_ERROR,
  payload: error
});
