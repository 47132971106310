import { takeLatest, put, call } from 'redux-saga/effects';
import { AIAgentsActionTypes } from '../actions/aiAgentsActionTypes';
import {
  readingComprehensionRequestSuccess,
  readingComprehensionRequestError,
  gradeReadingComprehensionRequestSuccess,
  gradeReadingComprehensionRequestError,
  matchTheFollowingRequestSuccess,
  matchTheFollowingRequestError,
  chessRequestSuccess,
  chessRequestError,
  gradeChessRequestSuccess,
  gradeChessRequestError,
  spellingRequestSuccess,
  spellingRequestError,
  gradeSpellingRequestSuccess,
  gradeSpellingRequestError,
  sattutorRequestSuccess,
  sattutorRequestError,
  gradeSATTutorRequestSuccess,
  gradeSATTutorRequestError,
  gradeMatchTheFollowingRequestSuccess,
  gradeMatchTheFollowingRequestError
} from '../actions/aiAgentsActions';
import { apiService } from '../../../services/api';

function* readingComprehensionRequestSaga(req: any) {
  try {
    // Call the API using the call effect
    const response = yield call(apiService.aiAgents, req.payload);
    // Dispatch the success action with the API response
    yield put(readingComprehensionRequestSuccess(response));
  } catch (error: any) {
    // Dispatch the error action
    yield put(readingComprehensionRequestError(error.message));
  }
}

function* gradeReadingComprehensionRequestSaga(req: any) {
  try {
    const response = yield call(apiService.aiAgents, req.payload);
    yield put(gradeReadingComprehensionRequestSuccess(response));
  } catch (error: any) {
    yield put(gradeReadingComprehensionRequestError(error.message));
  }
}

function* matchTheFollowingRequestSaga(req: any) {
  try {
    const response = yield call(apiService.aiAgents, req.payload);
    yield put(matchTheFollowingRequestSuccess(response));
  } catch (error: any) {
    yield put(matchTheFollowingRequestError(error.message));
  }
}

function* gradeMatchTheFollowingRequestSaga(req: any) {
  try {
    const response = yield call(apiService.aiAgents, req.payload);
    yield put(gradeMatchTheFollowingRequestSuccess(response));
  } catch (error: any) {
    yield put(gradeMatchTheFollowingRequestError(error.message));
  }
}

function* chessRequestSaga(req: any) {
  try {
    const response = yield call(apiService.aiAgents, req.payload);
    yield put(chessRequestSuccess(response));
  } catch (error: any) {
    yield put(chessRequestError(error.message));
  }
}

function* gradeChessRequestSaga(req: any) {
  try {
    const response = yield call(apiService.aiAgents, req.payload);
    yield put(gradeChessRequestSuccess(response));
  } catch (error: any) {
    yield put(gradeChessRequestError(error.message));
  }
}

function* spellingRequestSaga(req: any) {
  try {
    const response = yield call(apiService.handleSpelling, req.payload);
    yield put(spellingRequestSuccess(response));
  } catch (error: any) {
    yield put(spellingRequestError(error.message));
  }
}

function* gradeSpellingRequestSaga(req: any) {
  try {
    const response = yield call(apiService.handleSpelling, req.payload);
    yield put(gradeSpellingRequestSuccess(response));
  } catch (error: any) {
    yield put(gradeSpellingRequestError(error.message));
  }
}

function* sattutorRequestSaga(req: any) {
  try {
    const response = yield call(apiService.aiAgents, req.payload);
    yield put(sattutorRequestSuccess(response));
  } catch (error: any) {
    yield put(sattutorRequestError(error.message));
  }
}

function* gradeSATTutorRequestSaga(req: any) {
  try {
    const response = yield call(apiService.aiAgents, req.payload);
    yield put(gradeSATTutorRequestSuccess(response));
  } catch (error: any) {
    yield put(gradeSATTutorRequestError(error.message));
  }
}

export function* watchAIAgentsRequest() {
  yield takeLatest(AIAgentsActionTypes.ReadingComprehension_REQUEST, readingComprehensionRequestSaga);
  yield takeLatest(AIAgentsActionTypes.GradeReadingComprehension_REQUEST, gradeReadingComprehensionRequestSaga);
  yield takeLatest(AIAgentsActionTypes.MatchTheFollowing_REQUEST, matchTheFollowingRequestSaga);
  yield takeLatest(AIAgentsActionTypes.GradeMatchTheFollowing_REQUEST, gradeMatchTheFollowingRequestSaga);
  yield takeLatest(AIAgentsActionTypes.Chess_REQUEST, chessRequestSaga);
  yield takeLatest(AIAgentsActionTypes.GradeChess_REQUEST, gradeChessRequestSaga);
  yield takeLatest(AIAgentsActionTypes.Spelling_REQUEST, spellingRequestSaga);
  yield takeLatest(AIAgentsActionTypes.GradeSpelling_REQUEST, gradeSpellingRequestSaga);
  yield takeLatest(AIAgentsActionTypes.SATTutor_REQUEST, sattutorRequestSaga);
  yield takeLatest(AIAgentsActionTypes.GradeSATTutor_REQUEST, gradeSATTutorRequestSaga);
}
