import { BookingsAction, BookingsActionTypes } from './bookingsActionTypes';

export const bookings = (data: any): BookingsAction => ({
  type: BookingsActionTypes.BOOKINGS,
  payload: data
});

export const bookingsSuccess = (data: any): BookingsAction => ({
  type: BookingsActionTypes.BOOKINGS_SUCCESS,
  payload: data
});

export const bookingsError = (error: string): BookingsAction => ({
  type: BookingsActionTypes.BOOKINGS_ERROR,
  payload: error
});

export const resetBookings = (): BookingsAction => ({
  type: BookingsActionTypes.RESET_BOOKINGS
});

export const approveBooking = (data: any): BookingsAction => ({
  type: BookingsActionTypes.APPROVE_BOOKING,
  payload: data
});

export const approveBookingSuccess = (data: any): BookingsAction => ({
  type: BookingsActionTypes.APPROVE_BOOKING_SUCCESS,
  payload: data
});

export const approveBookingError = (error: string): BookingsAction => ({
  type: BookingsActionTypes.APPROVE_BOOKING_ERROR,
  payload: error
});

export const rejectBooking = (data: any): BookingsAction => ({
  type: BookingsActionTypes.REJECT_BOOKING,
  payload: data
});

export const rejectBookingSuccess = (data: any): BookingsAction => ({
  type: BookingsActionTypes.REJECT_BOOKING_SUCCESS,
  payload: data
});

export const rejectBookingError = (error: string): BookingsAction => ({
  type: BookingsActionTypes.REJECT_BOOKING_ERROR,
  payload: error
});
