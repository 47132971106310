import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from './configureStore';
import axios from '../Utils/axios';
import type { Event } from '../models/calendar';

interface CalendarState {
  events: Event[];
  isDrawerOpen: boolean;
  selectedEventId: string | null;
  selectedRange:
    | {
        start: number;
        end: number;
      }
    | {
        start: 0;
        end: 0;
      };
}

const initialState: CalendarState = {
  events: [],
  isDrawerOpen: false,
  selectedEventId: null,
  selectedRange: {
    start: 0,
    end: 0
  }
};

const ENV = process.env.REACT_APP_LANE;
const BASE_URL = `https://${process.env.REACT_APP_BASE_URL}/${ENV}`;

const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state: CalendarState, action: PayloadAction<{ events: Event[] }>) {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent(state: CalendarState, action: PayloadAction<{ event: Event }>) {
      const { event } = action.payload;

      state.events = [...state.events, event];
    },
    selectEvent(state: CalendarState, action: PayloadAction<{ eventId?: string }>) {
      const { eventId = null } = action.payload;

      state.isDrawerOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state: CalendarState, action: PayloadAction<{ event: Event }>) {
      const { event }: any = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          return event;
        }

        return _event;
      });
    },
    deleteEvent(state: CalendarState, action: PayloadAction<{ eventId: string }>) {
      const { eventId } = action.payload;

      state.events = _.reject(state.events, { id: eventId });
    },
    selectRange(state: CalendarState, action: PayloadAction<{ start: number; end: number }>) {
      const { start, end } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = false;
      state.selectedEventId = null;
      state.selectedRange = {
        start: 0,
        end: 0
      };
    }
  }
});

export const calendarReducer = calendarSlice.reducer;

export const getEvents: any =
  (req: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post<{ events: Event[] }>(`${BASE_URL}/GetEvents`, {
      requestor: localStorage.getItem('primaryGroup')
    });
    dispatch(calendarSlice.actions.getEvents(response.data));
  };

export const getCustomerEvents: any =
  (req: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post<{ events: Event[] }>(
      `${req?.user_api}/${ENV}/GetEvents`,
      {
        headers: {
          Authorization: `Bearer ${req?.idToken}`
        }
      },
      req?.user_id
    );
    dispatch(calendarSlice.actions.getEvents(response.data));
  };

export const createEvent: any =
  (data: any): AppThunk =>
  async (dispatch): Promise<void> => {
    const response = await axios.post<{ event: Event }>('/api/calendar/meetings/create', data);

    dispatch(calendarSlice.actions.createEvent(response.data));
  };

export const selectEvent: any =
  (eventId?: string): AppThunk =>
  async (dispatch) => {
    dispatch(calendarSlice.actions.selectEvent({ eventId }));
  };

export const updateEvent: any =
  (eventId: string, update: any): AppThunk =>
  async (dispatch) => {
    const response = await axios.post<{ event: Event }>('/api/calendar/meetings/update', {
      eventId,
      update
    });

    dispatch(calendarSlice.actions.updateEvent(response.data));
  };

export const deleteEvent: any =
  (eventId: string): AppThunk =>
  async (dispatch) => {
    await axios.post('/api/calendar/meetings/delete', {
      eventId
    });

    dispatch(calendarSlice.actions.deleteEvent({ eventId }));
  };

export const selectRange: any =
  (start: Date, end: Date): AppThunk =>
  (dispatch) => {
    dispatch(
      calendarSlice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };

export const openDrawerPanel: any = (): AppThunk => (dispatch) => {
  dispatch(calendarSlice.actions.openDrawerPanel());
};

// export const openDrawerPanel = (): AppThunk => async (dispatch: AppDispatch) => {
//   try {
//     dispatch(calendarSlice.actions.openDrawerPanel());
//   } catch (err: any) {
//     throw new Error(err);
//   }
// };

export const closeDrawerPanel: any = (): AppThunk => (dispatch) => {
  dispatch(calendarSlice.actions.closeDrawerPanel());
};

// export default calendarSlice.reducer;
