import { BookingsAction, BookingsActionTypes } from '../actions/bookingsActionTypes';

interface BookingsState {
  data: any;
  loading: boolean;
  error: string | null;
  approveBookingData: any;
  approveBookingLoading: boolean;
  approveBookingError: string | null;
  rejectBookingData: any;
  rejectBookingLoading: boolean;
  rejectBookingError: string | null;
}

const initialState: BookingsState = {
  data: [],
  loading: false,
  error: null,
  approveBookingData: null,
  approveBookingLoading: false,
  approveBookingError: null,
  rejectBookingData: null,
  rejectBookingLoading: false,
  rejectBookingError: null
};

const bookingsReducer = (state = initialState, action: BookingsAction): BookingsState => {
  switch (action.type) {
    case BookingsActionTypes.BOOKINGS:
      return { ...state, loading: true, data: action.payload };
    case BookingsActionTypes.BOOKINGS_SUCCESS:
      return { ...state, loading: false, error: null, data: action.payload };
    case BookingsActionTypes.BOOKINGS_ERROR:
      return { ...state, loading: false, error: action.payload };
    case BookingsActionTypes.RESET_BOOKINGS:
      return { ...state, data: [] };
    case BookingsActionTypes.APPROVE_BOOKING:
      return { ...state, approveBookingLoading: true, approveBookingData: action.payload };
    case BookingsActionTypes.APPROVE_BOOKING_SUCCESS:
      return { ...state, approveBookingLoading: false, approveBookingData: action.payload };
    case BookingsActionTypes.APPROVE_BOOKING_ERROR:
      return { ...state, approveBookingLoading: false, approveBookingError: action.payload };
    case BookingsActionTypes.REJECT_BOOKING:
      return { ...state, rejectBookingLoading: true, rejectBookingData: action.payload };
    case BookingsActionTypes.REJECT_BOOKING_SUCCESS:
      return { ...state, rejectBookingLoading: false, rejectBookingData: action.payload };
    case BookingsActionTypes.REJECT_BOOKING_ERROR:
      return { ...state, rejectBookingLoading: false, rejectBookingError: action.payload };
    default:
      return state;
  }
};

export default bookingsReducer;
