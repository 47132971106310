import { GetResourcesActionTypes, GetResourcesAction } from './getResourcesActionTypes';

export const getResourcesRequest = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES,
  payload: data
});

export const getResourcesRequestSuccess = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_SUCCESS,
  payload: data
});

export const getResourcesRequestError = (error: string): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_ERROR,
  payload: error
});

export const getResourcesByIdRequest = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_BY_ID,
  payload: data
});

export const getResourcesByIdRequestSuccess = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_BY_ID_SUCCESS,
  payload: data
});

export const getResourcesByIdRequestError = (error: string): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_BY_ID_ERROR,
  payload: error
});

export const getResourcesByFilters = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.GET_RESOURCES_BY_Filters,
  payload: data
});

export const storeResourcesOnCart = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.STORE_RESOURCES_ON_CART,
  payload: data
});

export const filterResourcesOnCart = (data: any): GetResourcesAction => ({
  type: GetResourcesActionTypes.FILTER_RESOURCES_ON_CART,
  payload: data
});

export const resetResourcesOnCart = (): GetResourcesAction => ({
  type: GetResourcesActionTypes.RESET_RESOURCES_ON_CART,
});