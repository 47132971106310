import { AIAgentsActionTypes, AIAgentsAction } from '../actions/aiAgentsActionTypes';

interface AIAgentsState {
  reading_comprehension_data: string[];
  grade_reading_comprehension_data: string[];
  match_the_following_data: string[];
  grade_match_the_following_data: string[];
  chess_data: string[];
  grade_chess_data: string[];
  spelling_data: string[];
  grade_spelling_data: string[];
  sattutor_data: string[];
  grade_sattutor_data: string[];
  loading: boolean;
  loaded: boolean;
  error: string | null;
}

const initialState: AIAgentsState = {
  reading_comprehension_data: [],
  grade_reading_comprehension_data: [],
  match_the_following_data: [],
  grade_match_the_following_data: [],
  chess_data: [],
  grade_chess_data: [],
  spelling_data: [],
  grade_spelling_data: [],
  sattutor_data: [],
  grade_sattutor_data: [],
  loading: false,
  loaded: false,
  error: null
};

const aiAgentsReducer = (state = initialState, action: AIAgentsAction): AIAgentsState => {
  switch (action.type) {
    case AIAgentsActionTypes.ReadingComprehension_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.ReadingComprehension_REQUEST_SUCCESS:
      return { ...state, reading_comprehension_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.ReadingComprehension_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.GradeReadingComprehension_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.GradeReadingComprehension_REQUEST_SUCCESS:
      return { ...state, grade_reading_comprehension_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.GradeReadingComprehension_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.MatchTheFollowing_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.MatchTheFollowing_REQUEST_SUCCESS:
      return { ...state, match_the_following_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.MatchTheFollowing_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.GradeMatchTheFollowing_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.GradeMatchTheFollowing_REQUEST_SUCCESS:
      return { ...state, grade_match_the_following_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.GradeMatchTheFollowing_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.Chess_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.Chess_REQUEST_SUCCESS:
      return { ...state, chess_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.Chess_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.GradeChess_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.GradeChess_REQUEST_SUCCESS:
      return { ...state, grade_chess_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.GradeChess_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.Spelling_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.Spelling_REQUEST_SUCCESS:
      return { ...state, spelling_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.Spelling_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.GradeSpelling_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.GradeSpelling_REQUEST_SUCCESS:
      return { ...state, grade_spelling_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.GradeSpelling_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    case AIAgentsActionTypes.SATTutor_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.SATTutor_REQUEST_SUCCESS:
      return { ...state, sattutor_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.GradeSATTutor_REQUEST:
      return { ...state, loading: true, loaded: false };
    case AIAgentsActionTypes.GradeSATTutor_REQUEST_SUCCESS:
      return { ...state, grade_sattutor_data: action.payload, loading: false, error: null, loaded: true };
    case AIAgentsActionTypes.GradeSATTutor_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload, loaded: false };
    default:
      return state;
  }
};

export default aiAgentsReducer;
