export enum SupportTicketActionTypes {
  GET_SUPPORT_TICKETS_REQUEST = 'supportTickets/GET_SUPPORT_TICKETS_REQUEST',
  GET_SUPPORT_TICKETS_REQUEST_SUCCESS = 'supportTickets/GET_SUPPORT_TICKETS_REQUEST_SUCCESS',
  GET_SUPPORT_TICKETS_REQUEST_ERROR = 'supportTickets/GET_SUPPORT_TICKETS_REQUEST_ERROR',
  CREATE_SUPPORT_TICKET_REQUEST = 'supportTickets/CREATE_SUPPORT_TICKET_REQUEST',
  CREATE_SUPPORT_TICKET_REQUEST_SUCCESS = 'supportTickets/CREATE_SUPPORT_TICKET_REQUEST_SUCCESS',
  CREATE_SUPPORT_TICKET_REQUEST_ERROR = 'supportTickets/CREATE_SUPPORT_TICKET_REQUEST_ERROR',
  UPDATE_SUPPORT_TICKET_REQUEST = 'supportTickets/UPDATE_SUPPORT_TICKET_REQUEST',
  UPDATE_SUPPORT_TICKET_REQUEST_SUCCESS = 'supportTickets/UPDATE_SUPPORT_TICKET_REQUEST_SUCCESS',
  UPDATE_SUPPORT_TICKET_REQUEST_ERROR = 'supportTickets/UPDATE_SUPPORT_TICKET_REQUEST_ERROR',
  DELETE_SUPPORT_TICKET_REQUEST = 'supportTickets/DELETE_SUPPORT_TICKET_REQUEST',
  DELETE_SUPPORT_TICKET_REQUEST_SUCCESS = 'supportTickets/DELETE_SUPPORT_TICKET_REQUEST_SUCCESS',
  DELETE_SUPPORT_TICKET_REQUEST_ERROR = 'supportTickets/DELETE_SUPPORT_TICKET_REQUEST_ERROR'
}

export interface SupportTicketAction {
  type: SupportTicketActionTypes;
  payload: any;
}
