import { Gen3RewardsActionTypes, Gen3RewardsAction } from './gen3RewardsActionTypes';

export const gen3RewardsRequest = (data: any): Gen3RewardsAction => ({
  type: Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST,
  payload: data
});

export const gen3RewardsRequestSuccess = (data: any): Gen3RewardsAction => ({
  type: Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST_SUCCESS,
  payload: data
});

export const gen3RewardsRequestError = (error: string): Gen3RewardsAction => ({
  type: Gen3RewardsActionTypes.GEN3_REWARDS_REQUEST_ERROR,
  payload: error
});

export const gen3RewardsClaimRequest = (data: any): Gen3RewardsAction => ({
  type: Gen3RewardsActionTypes.GEN3_REWARDS_CLAIM_REQUEST,
  payload: data
});

export const gen3RewardsClaimRequestSuccess = (data: any): Gen3RewardsAction => ({
  type: Gen3RewardsActionTypes.GEN3_REWARDS_CLAIM_REQUEST_SUCCESS,
  payload: data
});

export const gen3RewardsClaimRequestError = (error: string): Gen3RewardsAction => ({
  type: Gen3RewardsActionTypes.GEN3_REWARDS_CLAIM_REQUEST_ERROR,
  payload: error
});
