export enum createHomeworkActionTypes {
  CREATE_HOMEWORK = 'createHomework/CREATE_HOMEWORK',
  CREATE_HOMEWORK_SUCCESS = 'createHomework/CREATE_HOMEWORK_SUCCESS',
  CREATE_HOMEWORK_ERROR = 'createHomework/CREATE_HOMEWORK_ERROR',
  GET_SAMPLE_HOMEWORKS = 'createHomework/GET_SAMPLE_HOMEWORKS',
  GET_SAMPLE_HOMEWORKS_SUCCESS = 'createHomework/GET_SAMPLE_HOMEWORKS_SUCCESS',
  GET_SAMPLE_HOMEWORKS_ERROR = 'createHomework/GET_SAMPLE_HOMEWORKS_ERROR'
}

export interface createHomeworkAction {
  type: createHomeworkActionTypes;
  payload?: any;
}
