export enum BookingsActionTypes {
  BOOKINGS = 'bookings/BOOKINGS',
  BOOKINGS_SUCCESS = 'bookings/BOOKINGS_SUCCESS',
  BOOKINGS_ERROR = 'bookings/BOOKINGS_ERROR',
  RESET_BOOKINGS = 'bookings/RESET_BOOKINGS',
  APPROVE_BOOKING = 'bookings/APPROVE_BOOKING',
  APPROVE_BOOKING_SUCCESS = 'bookings/APPROVE_BOOKING_SUCCESS',
  APPROVE_BOOKING_ERROR = 'bookings/APPROVE_BOOKING_ERROR',
  REJECT_BOOKING = 'bookings/REJECT_BOOKING',
  REJECT_BOOKING_SUCCESS = 'bookings/REJECT_BOOKING_SUCCESS',
  REJECT_BOOKING_ERROR = 'bookings/REJECT_BOOKING_ERROR'
}

export interface BookingsAction {
  type: BookingsActionTypes;
  payload?: any;
}
