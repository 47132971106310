import { TutorsActionTypes, TutorsAction } from '../actions/getTutorActionTypes';

interface TutorsState {
  data: string[];
  loading: boolean;
  loaded: boolean;
  requesting: boolean;
  requested: boolean;
  request_error: string | null;
  error: string | null;
}

const initialState: TutorsState = {
  data: [],
  loading: false,
  loaded: false,
  requesting: false,
  requested: false,
  request_error: null,
  error: null
};

const tutorsReducer = (state = initialState, action: TutorsAction): TutorsState => {
  switch (action.type) {
    case TutorsActionTypes.TUTORS_REQUEST:
      return { ...state, loading: true };
    case TutorsActionTypes.TUTORS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case TutorsActionTypes.TUTORS_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    case TutorsActionTypes.SUBMIT_TUTORS_REQUEST:
      return { ...state, requesting: true };
    case TutorsActionTypes.SUBMIT_TUTORS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, requesting: false, requested: true, request_error: null };
    case TutorsActionTypes.SUBMIT_TUTORS_REQUEST_ERROR:
      return { ...state, requesting: false, request_error: action.payload };
    case TutorsActionTypes.TUTORS_ALL_REQUEST:
      return { ...state, loading: true };
    case TutorsActionTypes.TUTORS_ALL_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false, loaded: true, error: null };
    case TutorsActionTypes.TUTORS_ALL_REQUEST_ERROR:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export default tutorsReducer;
