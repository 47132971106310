export enum UserTasksActionTypes {
  USER_TASKS_REQUEST = 'userTasks/USER_TASKS_REQUEST',
  USER_TASKS_REQUEST_SUCCESS = 'userTasks/USER_TASKS_REQUEST_SUCCESS',
  USER_TASKS_REQUEST_ERROR = 'userTasks/USER_TASKS_REQUEST_ERROR',
  USER_TASKS_UPDATE_REQUEST = 'userTasks/USER_TASKS_UPDATE_REQUEST',
  USER_TASKS_UPDATE_REQUEST_SUCCESS = 'userTasks/USER_TASKS_UPDATE_REQUEST_SUCCESS',
  USER_TASKS_UPDATE_REQUEST_ERROR = 'userTasks/USER_TASKS_UPDATE_REQUEST_ERROR',
  USER_TASKS_DELETE_REQUEST = 'userTasks/USER_TASKS_DELETE_REQUEST',
  USER_TASKS_DELETE_REQUEST_SUCCESS = 'userTasks/USER_TASKS_DELETE_REQUEST_SUCCESS',
  USER_TASKS_DELETE_REQUEST_ERROR = 'userTasks/USER_TASKS_DELETE_REQUEST_ERROR',
  USER_TASKS_ADD_COMMENT_REQUEST = 'userTasks/USER_TASKS_ADD_COMMENT_REQUEST',
  USER_TASKS_ADD_COMMENT_REQUEST_SUCCESS = 'userTasks/USER_TASKS_ADD_COMMENT_REQUEST_SUCCESS',
  USER_TASKS_ADD_COMMENT_REQUEST_ERROR = 'userTasks/USER_TASKS_ADD_COMMENT_REQUEST_ERROR'
}

export interface UserTasksAction {
  type: UserTasksActionTypes;
  payload?: any;
}
