import { SupportTicketActionTypes, SupportTicketAction } from '../actions/supportTicketActionTypes';

interface SupportTicketState {
  data: any[];
  loading: boolean;
  error: string | null;
  create_data: any[];
  create_loading: boolean;
  create_error: string | null;
  update_data: any[];
  update_loading: boolean;
  update_error: string | null;
  delete_data: any[];
  delete_loading: boolean;
  delete_error: string | null;
}

const initialState: SupportTicketState = {
  data: [],
  loading: false,
  error: null,
  create_data: [],
  create_loading: false,
  create_error: null,
  update_data: [],
  update_loading: false,
  update_error: null,
  delete_data: [],
  delete_loading: false,
  delete_error: null
};

const supportTicketReducer = (
  state: SupportTicketState = initialState,
  action: SupportTicketAction
): SupportTicketState => {
  switch (action.type) {
    case SupportTicketActionTypes.GET_SUPPORT_TICKETS_REQUEST:
      return { ...state, loading: true };
    case SupportTicketActionTypes.GET_SUPPORT_TICKETS_REQUEST_SUCCESS:
      return { ...state, data: action.payload, loading: false };
    case SupportTicketActionTypes.GET_SUPPORT_TICKETS_REQUEST_ERROR:
      return { ...state, error: action.payload, loading: false };
    case SupportTicketActionTypes.CREATE_SUPPORT_TICKET_REQUEST:
      return { ...state, create_loading: true };
    case SupportTicketActionTypes.CREATE_SUPPORT_TICKET_REQUEST_SUCCESS:
      return { ...state, create_data: action.payload, create_loading: false };
    case SupportTicketActionTypes.CREATE_SUPPORT_TICKET_REQUEST_ERROR:
      return { ...state, create_error: action.payload, create_loading: false };
    case SupportTicketActionTypes.UPDATE_SUPPORT_TICKET_REQUEST:
      return { ...state, update_loading: true };
    case SupportTicketActionTypes.UPDATE_SUPPORT_TICKET_REQUEST_SUCCESS:
      return { ...state, update_data: action.payload, update_loading: false };
    case SupportTicketActionTypes.UPDATE_SUPPORT_TICKET_REQUEST_ERROR:
      return { ...state, update_error: action.payload, update_loading: false };
    case SupportTicketActionTypes.DELETE_SUPPORT_TICKET_REQUEST:
      return { ...state, delete_loading: true };
    case SupportTicketActionTypes.DELETE_SUPPORT_TICKET_REQUEST_SUCCESS:
      return { ...state, delete_data: action.payload, delete_loading: false };
    case SupportTicketActionTypes.DELETE_SUPPORT_TICKET_REQUEST_ERROR:
      return { ...state, delete_error: action.payload, delete_loading: false };
    default:
      return state;
  }
};

export default supportTicketReducer;
